import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`Happy Memorial Day!`}</em></p>
    <p>{`Make Up Day.`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`If you aren’t able to do Murph today or tomorrow from home (it will
be tomorrow’s scheduled wod) we will be doing a make up Murph on
Saturday, June 6th.  `}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`The Ville will reopen on June 1st at 6:00am!  Check out the
Schedule page for an updated list of class times.  Members will sign up
for classes one day ahead of time on the link we provide.  We will have
a maximum of 10 per class.  We hope to get back to our normal class
schedule asap and will keep you updated on any changes.`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      